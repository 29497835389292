<template>
  <div>
    <el-divider>" {{ $t("auth.login.or") }} "</el-divider>
    <div class="text-center">
      <div class="flex justify-center">
        <div class="mr-3 ml-3">
          <a href="https://www.facebook.com/">
            <img
              src="@/assets/Facebook_icon.png"
              alt=""
              class="w-8 h-8 rounded"
            />
          </a>
        </div>
        <div class="mr-3">
          <a href="https://twitter.com/tweeter">
            <img src="@/assets/tweeter.png" alt="" class="w-8 h-8 rounded" />
          </a>
        </div>
        <div class="mr-3">
          <a href="https://mail.google.com/">
            <img src="@/assets/gmail.png" alt="" class="w-8 h-8 rounded" />
          </a>
        </div>
        <div class="mr-3">
          <a href="https://gitlab.com/">
            <img src="@/assets/github.png" alt="" class="w-8 h-8 rounded" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
