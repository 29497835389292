import { Form, Field } from "vee-validate";
import * as yup from "yup";
import SSNLogin from "@/components/SSNLogin.vue";
import changeLanguage from "@/components/changeLanguage.vue";
import { URL } from "@/api/resgiter";
import InputWithValidation from "@/components/inputWidthvalidate.vue";

export default {
  name: "resgiter",
  components: {
    Field,
    Form,
    SSNLogin,
    changeLanguage,
    InputWithValidation,
  },
  data() {
    return {
      formValues: {
        hoTen: "",
        emailQuanLyTaiKhoan: "",
        password: "",
        password_confirm: "",
      },
    };
  },

  setup() {
    const login = yup.object({
      lastName: yup.string().required("Họ tên là một trường bắt buộc"),
      email: yup
        .string()
        .required("Email là một trường bắt buộc")
        .email("Email không hợp lệ"),
      password: yup
        .string()
        .required("Mật khẩu là một trường bắt buộc")
        .min(8, "Mật khẩu tối thiểu 8 ký tự"),
      confirm_password: yup
        .string()
        .required("Xác nhận mật khẩu là một trường bắt buộc")
        .oneOf([yup.ref("password")], "Mật khẩu không khớp"),
    });

    return {
      login,
    };
  },

  methods: {
    async resgiter() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "main" });
        return;
      }
      const data = this.formValues;
      const result = await this.$request({
        url: URL.RESGITER,
        method: "POST",
        data,
      });
      const res = result.data;
      if (res.code == 200) {
        this.$swal({
          text: "Đăng ký thành công vui lòng xác nhận email",
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
        this.$router.push({ name: "login" });
      } else {
        this.$swal({
          text: Object.values(res.errors[0]),
          icon: "error",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }
    },
  },
};
