<div class="resgiter">
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 h-screen">
    <div
      class="
        col-span-1
        md:col-span-1
        lg:col-span-2
        p-10
        md:block
        bg-gray-100
        hidden
      "
    >
      <div class="flex justify-between items-center">
        <img src="@/assets/icons/logo.svg" alt="" class="w-14 h-14" />
      </div>
      <div class="flex items-center justify-center">
        <img src="@/assets/resgiter-image.svg" alt="photo" class="lg:p-20" />
      </div>
    </div>

    <div class="p-5 md:p-14 grid items-center">
      <!-- <div class="flex justify-end">
        <changeLanguage />
      </div> -->
      <div class="mt-4 px-5 grid items-center">
        <h3 class="font-500 text-xl mb-2">{{$t("auth.resgiter.adventure")}}</h3>
        <p class="please text-base font-400 mb-5">
          {{$t("auth.resgiter.make")}}
        </p>

        <div class="mt-6">
          <Form as="el-form" :validation-schema="login" @submit="resgiter">
            <div class="form-name mt-3">
              <label>Họ tên</label>
              <InputWithValidation
                label="Họ tên"
                v-model="formValues.hoTen"
                name="lastName"
              />
            </div>

            <div class="form-email mt-3">
              <label>{{$t("auth.login.email")}}</label>
              <InputWithValidation
                label="Email"
                v-model="formValues.emailQuanLyTaiKhoan"
                name="email"
              />
            </div>

            <div class="form-password mt-3">
              <label>{{$t("auth.login.password")}}</label>
              <InputWithValidation
              type="password"
                label="Mật khẩu"
                v-model="formValues.password"
                name="password"
              />
            </div>

            <div class="form-password mt-3">
              <label>{{$t("auth.resetpassword.confirmpass")}}</label>
              <InputWithValidation
              type="password"
                label="Xác nhận mật khẩu"
                v-model="formValues.password_confirm"
                name="confirm_password"
              />
            </div>

            <div class="resgiter mt-10">
              <button
                class="
                  bg-blue-500
                  hover:bg-blue-700
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded
                "
                style="width: 100%"
              >
                {{$t("auth.resgiter.signup")}}
              </button>
            </div>

            <p class="have-account text-base mt-3">
              {{$t("auth.resgiter.already")}}
              <span class="text-blue-500 hover:text-blue-700">
                <router-link :to="{ name: 'login' }">
                  {{$t("auth.resgiter.signin")}}
                </router-link>
              </span>
            </p>

            <!-- <SSNLogin /> -->
          </Form>
        </div>
      </div>
    </div>
  </div>
</div>
